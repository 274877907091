import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  login,
  removeDeviceToken,
  updateDeviceToken,
} from "../services/authService";
import { onSignInSuccess, onSignOutSuccess } from "../store/auth/sessionSlice";
import { setUser, initialState } from "../store/auth/userSlice";
import appConfig from "../configs/app.config";
import useCryptograpy from "./useCryptograpy";
import { messaging } from "../firebase";
import { getToken } from "firebase/messaging";
import { useCallback, useEffect, useRef } from "react";
import { VAPID_KEY } from "../constants/app.constant";

function useAuth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { encrypt, decrypt } = useCryptograpy();

  const { token, signedIn, expired } = useSelector(
    (state) => state.auth.session
  );
  const codeId = useSelector((state) => state.auth.user.generatedCode);

  // Use ref to store the generatedDeviceToken across renders
  let generatedDeviceTokenRef = useRef("");

  const requestPermission = useCallback(async () => {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      // Generate Token
      const token = await getToken(messaging, {
        vapidKey: VAPID_KEY,
      });
      generatedDeviceTokenRef.current = token; // Store the token in the ref
      console.log("generatedDeviceTokenRef:>>>>>", generatedDeviceTokenRef);
      // Send this token  to server ( db)
    } else if (permission === "denied") {
      alert("You denied for the notification");
    }
  }, []);

  useEffect(() => {
    // Req user for notification permission
    requestPermission();
  }, [requestPermission]);

  const signIn = async (payload) => {
    try {
      const formData = {
        codeId: payload?.codeId,
      };

      const encryptedData = await encrypt(formData);
      const resp = await login({ encryptedData });
      if (resp?.success) {
        const decryptedData = await decrypt(resp.data);
        const token = decryptedData.token;
        const user = decryptedData.record;
        dispatch(onSignInSuccess(token));

        dispatch(
          setUser({
            code: user?.code ? user?.code : "null",
            generatedCode: user?.generatedCode ? user?.generatedCode : "null",
            expireDate: user?.expireDate ? user?.expireDate : "user",
            authority: ["patron"],
            profilesAssignedFormsFields: user?.profilesAssignedFormsFields
              ? user?.profilesAssignedFormsFields
              : [],
            groupsAssignedFormFields: user?.groupsAssignedFormFields
              ? user?.groupsAssignedFormFields
              : [],
            profileFieldsForProfiles: user?.profileFieldsForProfiles
              ? user?.profileFieldsForProfiles
              : [],
            surveyorFieldsForProfiles: user?.surveyorFieldsForProfiles
              ? user?.surveyorFieldsForProfiles
              : [],
            inferiorFieldsForProfiles: user?.inferiorFieldsForProfiles
              ? user?.inferiorFieldsForProfiles
              : [],
            profileFieldsForGroups: user?.profileFieldsForGroups
              ? user?.profileFieldsForGroups
              : [],
            surveyorFieldsForGroups: user?.surveyorFieldsForGroups
              ? user?.surveyorFieldsForGroups
              : [],
            inferiorFieldsForGroups: user?.inferiorFieldsForGroups
              ? user?.inferiorFieldsForGroups
              : [],
          })
        );
        if (generatedDeviceTokenRef.current) {
          try {
            const deviceTokenPayload = {
              codeId: payload?.codeId,
              deviceToken: generatedDeviceTokenRef.current,
            };
            const encryptedTokenData = await encrypt(deviceTokenPayload);
            await updateDeviceToken({
              encryptedData: encryptedTokenData,
            });
          } catch (err) {
            console.log("err : ", err);
          }
        } else {
          console.log("no device token ");
        }

        navigate(appConfig.authenticatedEntryPath);
        return {
          success: true,
          message: "Access Granted",
        };
      } else {
        return {
          success: false,
          message: resp?.message ? resp.message : "",
        };
      }
    } catch (err) {
      console.log(err);
      return {
        status: "failed",
        message: err?.response?.data?.error?.message || err.toString(),
      };
    }
  };

  const handleSignOut = async () => {
    if (generatedDeviceTokenRef.current) {
      try {
        const deviceTokenPayload = {
          codeId: codeId,
          deviceToken: generatedDeviceTokenRef.current,
        };
        const encryptedTokenData = await encrypt(deviceTokenPayload);
        await removeDeviceToken({
          encryptedData: encryptedTokenData,
        });
      } catch (err) {
        console.log("err : ", err);
      }
    } else {
      console.log("token not found");
    }
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    handleSignOut();
  };

  return {
    authenticated: token && signedIn && expired > new Date().getTime(),
    signIn,
    signOut,
  };
}

export default useAuth;
