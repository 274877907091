import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pagination: {
    total: "",
    currentPage: 1,
  },
};

const groupSlice = createSlice({
  name: "groupPagination",
  initialState,
  reducers: {
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
  },
});

export const { setPagination } = groupSlice.actions;
export default groupSlice.reducer;
