import React from 'react'

const SdCloseMenu = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M1.51562 1.51465L18.4862 18.4852"
          stroke="#1C274C"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          d="M18.4863 1.51465L1.51576 18.4852"
          stroke="#1C274C"
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
    </div>
  );
}

export default SdCloseMenu
