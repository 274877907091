import "./App.css";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store";
import history from "./history";
import Views from "./views";
import { Toaster } from "react-hot-toast";

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter history={history}>
          <Views />
        </BrowserRouter>
        <Toaster position="top-center" reverseOrder={false} />
      </PersistGate>
    </Provider>
  );
};

export default App;
