import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  authRoutes,
  protectedRoutes,
  publicRoutes,
} from "../configs/routes.config";
import AuthorityGuard from "../route/authorityGuard";
import AppRoute from "../route/appRoute";
import ProtectedRoute from "../route/protectedRoute";
import Loading from "../components/ui/loading";
import Header from "../components/template/header";
import AuthRoute from "../route/authRoute";
import PublicRoute from "../route/publicRoute";
import { LOGIN_PATH } from "../constants/route.constant";
import MenuSection from "../components/template/menuSection";
import MenuBar from "../components/template/menuBar";
import NavLogo from "../components/template/navLogo";

const HeaderActionsStart = () => {
  return (
    <>
      <NavLogo type="full" imgClass="mx-auto" logoWidth={100} isAuth={true} />
    </>
  );
};
const HeaderActionsMiddle = () => {
  return (
    <>
      <MenuSection />
    </>
  );
};

const HeaderActionsEnd = (user) => {
  return (
    <>
      <MenuBar user={user} />
    </>
  );
};

const Views = () => {
  const { user } = useSelector((state) => state.auth);

  // check user authority to check route access
  const userAuthority = useSelector((state) => state.auth.user.authority);
  return (
    <Suspense fallback={<Loading size={40} />}>
      <Routes>
        {/* Redirect root path to /login */}
        <Route path="/" element={<Navigate to={LOGIN_PATH} replace />} />
        {/* map auth routes which are only access when user is not authenticated, 
        if user authentic then it will redirect to authenticated entrypath */}
        <Route path="/" element={<AuthRoute />}>
          {authRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <AppRoute
                  routeKey={route.key}
                  component={route.component}
                  {...route.meta}
                />
              }
            />
          ))}
        </Route>
        {/* map private routes which are only access when user is authenticated, 
        if user is not authentic then it will redirect to unauthenticated entrypath */}
        <Route path="/" element={<ProtectedRoute />}>
          {protectedRoutes.map((route, index) => (
            <Route
              key={route.key + index}
              path={route.path}
              element={
                <AuthorityGuard
                  userAuthority={userAuthority}
                  authority={route.authority}
                >
                  <Header
                    className="shadow-header"
                    container={true}
                    headerStart={<HeaderActionsStart />}
                    headerMiddle={<HeaderActionsMiddle />}
                    headerEnd={<HeaderActionsEnd user={user} />}
                  />
                  <AppRoute
                    routeKey={route.key}
                    component={route.component}
                    {...route.meta}
                  />
                </AuthorityGuard>
              }
            />
          ))}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
        {/* map public routes which are access when user is authenticated or not authenticated */}
        <Route path="/" element={<PublicRoute />}>
          {publicRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <AppRoute
                  routeKey={route.key}
                  component={route.component}
                  {...route.meta}
                />
              }
            />
          ))}
        </Route>
      </Routes>
    </Suspense>
  );
};

export default Views;
