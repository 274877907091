import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { HiOutlineLogout } from "react-icons/hi";
import useAuth from "../../hooks/useAuth";
import Avatar from "../ui/avatar";
import Dropdown from "../ui/dropdown";
import { Link } from "react-router-dom";
import withHeaderItem from "../../utils/withHeaderItem";

// example of dropdown menu object
// {
//     label: "Profile",
//     path: "/",
//     icon: <HiOutlineUser />,
//   }
const dropdownItemList = [];

export const UserDropdown = ({ className }) => {
  const { user } = useSelector((state) => state.auth);

  const { signOut } = useAuth();

  const UserAvatar = (
    <div className={classNames(className, `flex items-center gap-2 `)}>
      <Avatar size={40} shape="circle" src={`/img/other/user.png`} />
    </div>
  );

  return (
    <div className="cursor-pointer">
      <Dropdown
        menuStyle={{ minWidth: 240 }}
        renderTitle={UserAvatar}
        placement="bottom-end"
      >
        <Dropdown.Item variant="header">
          <div className="py-2 px-3 flex items-center gap-2">
            <div>
              <div className="font-normal text-gray-900">
                {user?.generatedCode}
              </div>
            </div>
          </div>
        </Dropdown.Item>
        {dropdownItemList.map((item) => (
          <Dropdown.Item
            eventKey={item.label}
            key={item.label}
            className="mb-1"
          >
            <Link className="flex gap-2 items-center" to={item.path}>
              <span className="text-xl opacity-50">{item.icon}</span>
              <span>{item.label}</span>
            </Link>
          </Dropdown.Item>
        ))}
        <Dropdown.Item variant="divider" />
        <Dropdown.Item onClick={signOut} eventKey="Sign Out" className="gap-2">
          <span className="text-xl opacity-50">
            <HiOutlineLogout />
          </span>
          <span>Sign Out</span>
        </Dropdown.Item>
      </Dropdown>
    </div>
  );
};

export default withHeaderItem(UserDropdown);
